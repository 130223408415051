@import url('https://use.typekit.net/pjw8bzp.css');
@import "colors.sass";
@import "variables.sass";
@import "flex.sass";

* {
  box-sizing: border-box; }

html {
  font-size: 16px; }

body {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'effra', "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  .MuiAlert-standardError {
    @media (max-width: $xs-ends) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 500;
      width: calc(100% - 12px);
      margin: 4px; } }

  .MuiFormLabel-root {
    font-size: 19px;
    font-weight: 400;
    color: #2d2d2d; }

  .MuiFormHelperText-root {
    font-size: 14px; }

  .MuiInputAdornment-positionEnd {
    .MuiIconButton-root {
      padding: 4px; } }
  .MuiInputBase-root {
    border: 2px solid #eaeaea;
    border-radius: 3px;

    .MuiInputBase-input {

      border-radius: 3px;
      padding: 8px; }

    .MuiInput-formControl {
      border-radius: 3px; }

    .MuiInput-underline:before {
      border: none; }

    .MuiInputLabel-shrink {
      font-size: 16px;
      font-weight: 600;
      color: #2d2d2d; }

    .MuiFormLabel-filled + .MuiInput-formControl {
      background-color: #E7EFFF; } }

  .MuiContainer-root {
    @media (max-width: $xs-ends) {
      padding-left: 4px;
      padding-right: 4px; } }

  .MuiPaper-root {
    @media (max-width: $xs-ends) {
      padding-left: 4px;
      padding-right: 4px; } }

  .MuiCardHeader-root {
    @media (max-width: $xs-ends) {
      padding-left: 4px;
      padding-right: 4px; } }

  .MuiCardContent-root {
    @media (max-width: $xs-ends) {
      padding-left: 4px;
      padding-right: 4px; } }

  .MuiButtonBase-root {
    @media (max-width: $xs-ends) {
      padding-left: 4px;
      padding-right: 4px; } }

  .MuiExpansionPanelDetails-root {
    @media (max-width: $xs-ends) {
      padding-left: 4px;
      padding-right: 4px; } }

  .MuiAlert-action {
    @media (max-width: $xs-ends) {
      margin-right: 0; } }

  .MuiAlert-icon {
    @media (max-width: $xs-ends) {
      margin-left: 4px;
      margin-right: 8px; } } }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

p,
a,
b,
pre,
code,
input {
  color: $text-color; }

a {
  text-decoration: none; }

.remove-outline {
  &, &:hover, &:focus {
    outline: none; } }

.pointer {
  cursor: pointer; }

.reset-list-styles {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.full-height {
  height: 100%; }

.line {
  height: 0.25rem;
  width: 100%;
  background: $gray-lightest; }

.overflow-hidden {
  overflow: hidden; }

.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center; }

@keyframes loaded {
  0% {
    opacity: 0.1; }

  100% {
    opacity: 1; } }
